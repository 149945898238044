.mainAuth {
    background-color: #f8f7f3;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mainAuthEdge {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    /* background: linear-gradient(150deg, #f8f7f3 0%, #1d242d 100%); */
  }
  .mainAuthEdge__fone {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
  }
  .authForm__btn {
    min-width: 100% !important;
    font-family: inherit !important;
    background: #201c27 !important;
    font-weight: 500 !important;
    width: 250px !important;
    height: 48px !important;
    font-size: 16px !important;
  }
  .authForm__backBtn {
    font-size: 16px !important;
    gap: 10px;
    font-weight: 700 !important;
    font-family: inherit !important;
    color: #201c27 !important;
  }
  .mainAuthEdge__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      259deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  
  @media (max-width: 850px) {
    .mainAuthEdge {
      width: 100%;
    }
  }
  
  .authForm {
    height: 620px;
    display: flex;
    border-radius: 20px;
    background: rgba(249, 249, 249, 0.95);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  }
  .authForm__auth {
    width: 100%;
    height: 100%;
  }
  .authForm__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  .authForm__back {
    max-width: 400px;
    padding-top: 30px;
    width: 100%;
  }
  .authForm__btnBack {
    font-family: "Fira Sans";
    font-size: 16px;
  }
  .authForm__decaor {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .authForm__decaorSocial {
    background: #1d242d;
    width: 100%;
    border-radius: 0px 0px 20px 0px;
    height: 18%;
    display: flex;
    align-items: flex-end;
  }
  .authForm__img {
    border-radius: 0px 20px 0 0;
    display: flex;
    /* background: url(/image/authFone.min.jpg); */
    background-size: cover;
    background-position: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    height: 90%;
    width: 100%;
    object-fit: cover;
  }
  .wait__info {
    height: 100vh;
    display: grid;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
  }
  .socialDescription__span {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .authForm__subTitle {
    color: #afafaf;
    margin-bottom: 0;
    text-align: center;
    max-width: 70%;
  }
  .wait__block {
    background-color: #eeeeeb;
    border: #afafaf 1px solid;
    border-radius: 8px;
    height: 140px;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
  .wait__block p {
    font-weight: 600;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    color: #404040;
    font-size: 18px;
    font-family: inherit;
  }
  .authForm__description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .socialDescription {
    display: flex;
    gap: 10px;
    padding: 0 0 15px 30px;
  }
  .authForm__auth {
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .authForm__controllerReg {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .authForm__controller {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  .authForm__title {
    text-align: center;
  }
  .authForm__body {
    max-width: 400px;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  @media (max-width: 850px) {
    .authForm__decaor {
      display: none;
    }
    .authForm__back {
      padding-left: 20px;
    }
    .authForm__content {
      width: 100%;
    }
  }
  
  @media (max-width: 400px) {
    .authForm {
      height: 560px;
    }
    .authForm__subTitle {
      max-width: 100%;
    }
    .authForm__auth {
      gap: 25px;
    }
  }
  
  @media (max-width: 400px) {
    .authForm__controllerReg {
      flex-direction: column;
    }
  }
  
  .registerForm {
    height: 620px;
    display: flex;
    border-radius: 20px;
    background: rgba(249, 249, 249, 0.95);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  }
  .registerForm__decaor {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .registerForm__img {
    border-radius: 20px 0 0 0;
    /* background: url(/image/authFone.min.jpg); */
    background-size: cover;
    background-position: center;
    display: flex;
    position: absolute;
    top: 0;
    height: 90%;
    width: 100%;
  }
  .registerForm__decaorSocial {
    background: #1d242d;
    width: 100%;
    border-radius: 0px 0px 0px 20px;
    height: 18%;
    display: flex;
    align-items: flex-end;
  }
  
  .hasScroll::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  @media (max-width: 850px) {
    .registerForm__decaor {
      display: none;
    }
  }
  @media (max-width: 400px) {
    .registerForm {
      height: 560px;
    }
  }
  