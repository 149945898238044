.titleFine {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.oldPrice{
  font-family: inherit;
  text-decoration: line-through;
  color: #949494;
}
.price {
  font-family: inherit;
  font-weight: 700;
}
.priceContainer {
  display: flex;
  flex-direction: column;
}
.fineInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}