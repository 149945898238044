.eb__btn {
  text-transform: capitalize!important;
  white-space: nowrap!important;
  max-width: 160px!important;
  width: 100%!important;
  gap: 6px!important;
  font-weight: 500!important;
  padding: 8px 16px!important;
  font-family: inherit!important;
}
.eb__badge {
  max-width: 160px!important;
  font-family: inherit!important;
  font-weight: 500!important;
  width: 100%!important;
}
.countUnPaidFine {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  background-color: #D25834;
}
.fastPagination {
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  max-width: 48px;
  border-style: solid;
  padding: 0 8px;
  border-color: rgba(224, 224, 224, 1);
}
.countPaidFine {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  background-color: #7DB55E;
}
.containerCountFine {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.unPaid {
  padding: 4px 8px;
  color: #333333;
  font-weight: 600;
  border-radius: 4px;
  background-color: #E3E3E3;
}
.disabledPaid {
  padding: 4px 8px;
  color: #333333;
  font-weight: 600;
  border-radius: 4px;
  background-color: #D25834;
}
.paid {
  padding: 4px 8px;
  color: #333333;
  font-weight: 600;
  border-radius: 4px;
  background-color: #7DB55E;
}